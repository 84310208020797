<template>
  <div>
    <b-row v-if="onboardData.length > 0" class="py-2 align-items-middle">
      <b-col lg="4" class="on-boarding">
        <b-card>
          <b-card-body>
            <b-card-title title-tag="h2" class="font-weight-bold mb-3">
              <h1>Let's Start</h1>
            </b-card-title>
            <ul class="todo">
              <li
                v-for="(onboard, index) in onboardData.filter(
                  (item) => item.onboard_completed
                )"
                :key="onboard.title"
                class="is-complete"
              >
                <h4>{{ onboard.title }}</h4>
              </li>
              <li
                v-for="(onboard, index) in onboardData.filter(
                  (item) => !item.onboard_completed
                )"
                :key="onboard.title"
                class="is-current"
              >
                <h4>{{ onboard.title }}</h4>
                <div class="content" v-if="index + 1 == 1">
                  <p>{{ onboard.description }}</p>
                  <b-button
                    variant="gradient-primary"
                    @click="
                      addOnboard(
                        onboard.name,
                        onboard.title,
                        onboard.description
                      )
                    "
                  >
                    {{ onboard.title }}
                  </b-button>
                </div>
              </li>
            </ul>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col lg="8">
        <b-card v-if="onboardName == 'skills'" class="onboard-form">
          <b-card-body>
            <Skill
              :title="onboardTitle"
              :description="onboardDescription"
              :onboardAPi="onboard"
              :nextOnboard="addOnboard"
              :nextName="
                onboardData.filter((item) => !item.onboard_completed).length > 0
                  ? onboardData.filter((item) => !item.onboard_completed)[0]
                      .name
                  : null
              "
              :nextTitle="
                onboardData.filter((item) => !item.onboard_completed).length > 0
                  ? onboardData.filter((item) => !item.onboard_completed)[0]
                      .title
                  : null
              "
            />
          </b-card-body>
        </b-card>
        <b-card v-else-if="onboardName == 'info'" class="onboard-form">
          <b-card-body>
            <BasicInfo
              :title="onboardTitle"
              :description="onboardDescription"
              :onboardAPi="onboard"
              :nextOnboard="addOnboard"
              :nextName="
                onboardData.filter((item) => !item.onboard_completed)[0].name
              "
              :nextTitle="
                onboardData.filter((item) => !item.onboard_completed)[0].title
              "
            />
          </b-card-body>
        </b-card>

        <b-card v-else-if="onboardName == 'experiences'" class="onboard-form">
          <b-card-body>
            <Experience
              :title="onboardTitle"
              :description="onboardDescription"
              :onboardAPi="onboard"
              :nextOnboard="addOnboard"
              :nextName="
                onboardData.filter((item) => !item.onboard_completed)[0].name
              "
              :nextTitle="
                onboardData.filter((item) => !item.onboard_completed)[0].title
              "
            />
          </b-card-body>
        </b-card>

        <b-card v-else-if="onboardName == 'preference'" class="onboard-form">
          <b-card-body>
            <Preference
              :title="onboardTitle"
              :description="onboardDescription"
              :onboardAPi="onboard"
              :nextOnboard="addOnboard"
              :nextName="
                onboardData.filter((item) => !item.onboard_completed)[0].name
              "
              :nextTitle="
                onboardData.filter((item) => !item.onboard_completed)[0].title
              "
            />
          </b-card-body>
        </b-card>

        <b-card v-else-if="onboardName == 'candidateUrls'" class="onboard-form">
          <b-card-body>
            <AddUrl
              :title="onboardTitle"
              :description="onboardDescription"
              :nextOnboard="addOnboard"
              :nextName="
                onboardData.filter((item) => !item.onboard_completed).length > 0
                  ? onboardData.filter((item) => !item.onboard_completed)[0]
                      .name
                  : null
              "
              :nextTitle="
                onboardData.filter((item) => !item.onboard_completed).length > 0
                  ? onboardData.filter((item) => !item.onboard_completed)[0]
                      .title
                  : null
              "
            />
          </b-card-body>
        </b-card>
        <b-card v-else-if="onboardName == 'cvs'" class="onboard-form">
          <b-card-body>
            <Cv
              :title="onboardTitle"
              :description="onboardDescription"
              :nextOnboard="addOnboard"
              :nextName="
                onboardData.filter((item) => !item.onboard_completed).length > 0
                  ? onboardData.filter((item) => !item.onboard_completed)[0]
                      .name
                  : null
              "
              :nextTitle="
                onboardData.filter((item) => !item.onboard_completed).length > 0
                  ? onboardData.filter((item) => !item.onboard_completed)[0]
                      .title
                  : null
              "
            />
          </b-card-body>
        </b-card>
        <b-card v-else-if="onboardName == 'educations'" class="onboard-form">
          <b-card-body>
            <Education
              :title="onboardTitle"
              :description="onboardDescription"
              :nextOnboard="addOnboard"
              :nextName="
                onboardData.filter((item) => !item.onboard_completed).length > 0
                  ? onboardData.filter((item) => !item.onboard_completed)[0]
                      .name
                  : null
              "
              :nextTitle="
                onboardData.filter((item) => !item.onboard_completed).length > 0
                  ? onboardData.filter((item) => !item.onboard_completed)[0]
                      .title
                  : null
              "
            />
          </b-card-body>
        </b-card>
        <div
          v-else
          class="w-100 d-lg-flex align-items-center justify-content-center px-2"
        >
          <b-img fluid :src="imgUrl" alt="Login V2" />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BCard,
  BImg,
  BForm,
  BButton,
  BCardBody,
} from "bootstrap-vue";

import store from "@/store/index";
import { HasError, AlertError } from "vform/src/components/bootstrap5";
import BasicInfo from "@/views/components/onboard/BasicInfo.vue";
import Skill from "@/views/components/onboard/Skill.vue";
import Experience from "@/views/components/onboard/Experience.vue";
import AddUrl from "../components/onboard/AddUrl.vue";
import Cv from "../components/onboard/Cv.vue";
import Education from "../components/onboard/Education.vue";
import Preference from "@/views/components/onboard/Preference.vue";

export default {
  components: {
    BasicInfo,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BCard,
    BImg,
    BForm,
    BButton,
    VuexyLogo,
    HasError,
    AlertError,
    Skill,
    Experience,
    BCardBody,
    AddUrl,
    Cv,
    Education,
    Preference,
  },
  data() {
    return {
      sideImg: require("@/assets/images/pages/onboard.svg"),
      onboardName: null,
      onboardTitle: null,
      onboardDescription: null,
    };
  },
  mounted() {
    this.$store.dispatch("onboardData");
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        this.sideImg = require("@/assets/images/pages/onboard.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
    onboardData() {
      return this.$store.getters.onboardData;
    },
  },
  methods: {
    addOnboard(onboardName = null, title = null, description = null) {
      this.onboardName = onboardName;
      this.onboardTitle = title;
      this.onboardDescription = description;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/pages/page-auth.scss";
</style>
<style>
.onboard-form .card-body {
  display: flex;
  align-items: center;
  width: 100%;
}
.on-boarding ul.todo {
  margin: 0;
  padding: 0;
  color: #232323;
  font-size: 20px;
  cursor: default;
  list-style-type: none !important;
}

.on-boarding ul.todo li {
  margin-bottom: 20px;
  position: relative;
  padding-left: 40px;
}
.on-boarding ul.todo li h4 {
  font-size: 20px;
}
.on-boarding ul.todo li p {
  margin-bottom: 10px;
}
.button.is-primary {
  background-color: #ff8d00;
  border-color: transparent;
  color: #fff;
}
.on-boarding ul.todo li.is-complete:before {
  border-color: #1db44e;
  background: #dfffea;
}
.on-boarding ul.todo li:before {
  content: "";
  height: 30px;
  width: 30px;
  position: absolute;
  left: 0;
  top: 0;
  border: 2px solid #ddd;
  border-radius: 50%;
}

.on-boarding ul.todo li.is-complete:after {
  content: "";
  position: absolute;
  left: 9px;
  top: 10px;
  height: 7px;
  width: 13px;
  border-left: 2px solid #1db44e;
  border-bottom: 2px solid #1db44e;
  transform: rotate(-45deg);
}
</style>
